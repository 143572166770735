import { useGlobalStore } from "@/store/global"

export const toggleSidebar = (name: string) => {
    const store = useGlobalStore()

    store.toggleSidebar(name)

    if (process.client && name != "announcements") {
        if (store.getSidebar.value) {
            const widthBefore = document.body.offsetWidth

            document.body.classList.add(`overflow-hidden`)
            document.body.style.marginRight =
                document.body.offsetWidth - widthBefore + "px"
        } else {
            document.body.classList.remove(`overflow-hidden`)
            document.body.style.marginRight = "0"
        }
    }
}
